module.exports = [{
      plugin: require('C:/projs/entracker/repo/entracker-site/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('C:/projs/entracker/repo/entracker-site/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/projs/entracker/repo/entracker-site/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('C:/projs/entracker/repo/entracker-site/node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"lang":"en","siteId":1607614,"cookiePolicyId":75164774,"cookiePolicyUrl":"https://entracker.com/cookies/","banner":{"prependOnBody":false,"acceptButtonDisplay":true,"acceptButtonColor":"#448DF4","acceptButtonCaptionColor":"white","position":"bottom","textColor":"#676767","backgroundColor":"#ffffff","fontSizeBody":"11px"}},"googleTagManagerOptions":true},
    },{
      plugin: require('C:/projs/entracker/repo/entracker-site/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PWNTHT7","includeInDevelopment":true},
    },{
      plugin: require('C:/projs/entracker/repo/entracker-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
